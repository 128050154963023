document.addEventListener("DOMContentLoaded", function () {
    const menuToggle = document.getElementById("menu-toggle");
    const closeMenu = document.getElementById("close-menu");
    const mobileMenu = document.getElementById("mobile-menu");

    // Abrir el drawer
    menuToggle.addEventListener("click", () => {
        mobileMenu.classList.remove("translate-x-full");
    });

    // Cerrar el drawer
    closeMenu.addEventListener("click", () => {
        mobileMenu.classList.add("translate-x-full");
    });

});
